'use strict';

var debounce = require('lodash/debounce');

const init = () => {
    const $body = $('body');

    $('.ita-carousel').each(function() {
        const $this = $(this);
        const $container = $this.closest('.ita-carousel-container');

        const autoplay = $container.data('autoplay');
        const autoplaySpeed = $container.data('autoplay-speed');
        const pauseOnHover = $container.data('pause-on-hover');

        $this.slick({
            autoplay: autoplay,
            autoplaySpeed: autoplaySpeed,
            arrows: false,
            centerMode: true,
            centerPadding: '16px',
            lazyLoad: 'progressive',
            mobileFirst: true,
            pauseOnFocus: false,
            pauseOnHover: pauseOnHover,
            speed: 1200,
            cssEase: 'ease-in-out',
            slidesPerRow: 1,
            slidesToShow: 1,
            useTransform:false,
            responsive: [
                {
                  breakpoint: 768,
                  settings: {
                    arrows: false,
                    centerPadding: '5%'
                  }
                }
            ]
        });
    });

    if (!window.app.isMobile()) {
        initAwardCarousel();
    }

    $(window).on('resize', debounce(() => {
        if (window.app.isMobile()) {
            disableAwardCarousel();
        } else {
            initAwardCarousel();
        }
    }, 250));

    $('.js-ita-carousel-award-show-more').on('click', (e) => {
        const $this = $(e.target);
        $this.closest('.ita-carousel-award').find('.ita-carousel-award__slider-wrapper > .hidden').removeClass('hidden');
        $this.hide();
    });

    $body.on('click', '.js-product-tile-amadeus-small', (e) => {
        let $item = $(e.currentTarget);

        let amadeusData = $item.data('amadeus');

        if (amadeusData) {
            $body.trigger('amadeus:select', amadeusData);
        }
    });
};

const initAwardCarousel = () => {
    $('.ita-carousel-award__slider-wrapper').each(function () {
        const $this = $(this);
        const slidesCount = parseInt($this.closest('.ita-carousel-award').attr('data-slides-count'));

        $(this).slick({
            slidesToShow: slidesCount || 3,
            slidesToScroll: slidesCount || 3,
            dots: true,
            infinite: false,
            prevArrow: '<button type="button" class="slick-prev"></button>',
            nextArrow: '<button type="button" class="slick-next"></button>',
        });
    });
};

const disableAwardCarousel = () => {
    $('.ita-carousel-award__slider-wrapper.slick-initialized').each(function () {
        $(this).slick('unslick');
    });
}

module.exports = {
    init,
    methods: {
        initAwardCarousel: initAwardCarousel,
        disableAwardCarousel: disableAwardCarousel
    }
};
